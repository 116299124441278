<template>
  <v-container
    class="background"
    fill-height
    fluid
  >
    <default-modal
      v-if="showModal"
      :action="modalState.action"
      :cancel-message="modalState.cancelMessage"
      :message="modalState.message"
      @action="doAction"
      @close="closeModal"
    />
    <task-option-modal
      v-if="showTaskOptionModal"
      :defaultNumber="defaultNumber"
      option="task"
      @action="addWorkToJob"
      @close="closeOptionModal"
    />
    <v-row class="left-right-container">
      <v-col class="left-area ma-1">
        <map-component
          mode="map"
        />
      </v-col>
      <v-col class="right-area ma-1" v-if="showList">
        <template v-if="showLocations">
          <v-col
            class="btn-area"
          >
            <template v-if="jobs.length">
              <v-col>
                  <v-col cols="12">
                    <h1 class="title-task">
                      진행중 태스크
                    </h1>
                  </v-col>
                  <template v-if="!$store.state.isTaskStop">
                    <v-col class="pa-0">
                      <v-card
                        class="task"
                        color="#d4d4d4"
                      >
                        <v-row justify="space-between">
                          <v-col>
                            <span class="ml-5">
                              {{ workName }}
                            </span>
                          </v-col>
                          <v-col>
                            <span class="ml-5">
                              {{ progress }}
                            </span>
                          </v-col>
                          <v-spacer/>
                          <v-col>
                            <v-btn
                              class="btn-task"
                              color="#00BFB4"
                              dark
                              height="70px"
                              @click="clickTaskPauseBtn"
                            >
                              정지
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col class="pa-0">
                      <v-card
                        class="task"
                        color="#d4d4d4"
                      >
                        <v-row justify="space-between">
                          <v-col>
                            <span class="ml-5">
                              {{ workName }}
                            </span>
                          </v-col>
                          <v-col>
                            <span class="ml-5">
                              {{ progress }}
                            </span>
                          </v-col>
                          <v-col>
                            <v-btn
                              class="btn-task"
                              color="#00BFB4"
                              dark
                              height="70px"
                              @click="clickTaskPauseBtn"
                            >
                              재개
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              class="btn-task"
                              color="#7c7c7c"
                              dark
                              height="70px"
                              @click="cancelTask"
                            >
                              취소
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </template>
              </v-col>
            </template>

            <template v-if="workAllList.length">
              <v-col cols="12">
                <h1 class="title-task">
                  태스크 목록
                </h1>
              </v-col>
              <ul class="btn-list">
                <li
                    v-for="(item, i) in workAllList"
                    :key="i"
                    class="btn mt-3"
                    @click="addOnceWorkToJob(i)"
                >
                  <v-btn>
                    {{ item.work_name }}
                  </v-btn>
                </li>
              </ul>
            </template>

            <v-col align-self="center">
              <v-col cols="12 mt-5" style="padding-bottom: 0;">
                <h1 class="title-task">
                  위치 이동
                </h1>
              </v-col>
              <template v-if="locations.length">
                <ul class="btn-list">
                  <li
                    v-for="(item, i) in locations"
                    :key="i"
                    class="btn mt-3"
                    @click="moveToLocation(item.location_id, item.location_name)"
                  >
                    <v-btn>
                      {{ item.location_name }}
                    </v-btn>
                  </li>
                </ul>
              </template>
              <template v-else>
                <p class="empty">
                  저장된 위치가 없습니다.
                </p>
              </template>
            </v-col>
          </v-col>
        </template>
        <template v-else>
          <v-row
            class="move-view"
            justify="center"
          >
            <v-col
              align-self="end"
              cols="auto"
            >
              <h1 class="move-title">
                {{ destination }}
              </h1>
            </v-col>
            <template v-if="showMoving">
              <v-col
                class="move-state"
                cols="12"
              >
                <p> 이동중입니다 </p>
                <v-btn
                  class="btn-state mt-4"
                  color="#00BFB4"
                  dark
                  @click="stopMoving"
                >
                  이동정지
                </v-btn>
              </v-col>
            </template>
            <template v-if="showStop">
              <v-col
                class="move-state"
                cols="12"
              >
                <p> 이동정지 </p>
                <v-btn
                  class="btn-state mt-2"
                  color="#7c7c7c"
                  dark
                  @click="cancelMoving"
                >
                  이동취소
                </v-btn>
                <br>
                <v-btn
                  class="btn-state mt-1"
                  color="#00BFB4"
                  dark
                  @click="keepMoving"
                >
                  이동계속
                </v-btn>
              </v-col>
            </template>
            <template v-if="showArrived">
              <v-col
                class="move-state"
                cols="12"
              >
                <p> 도착했습니다 </p>
                <v-btn
                  class="btn-state mt-9"
                  color="#00BFB4"
                  dark
                  @click="cancelMoving"
                >
                  메인메뉴
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </template>
      </v-col>
      <default-header
        :config="true"
        :home="false"
        :controller="true"
        :goto="true"
        :reload="true"
        :servo="true"
        :set-pose="true"
      />
    </v-row>
  </v-container>
</template>

<script>
import MapComponent from '@/components/map/map.vue';
import EventBus from '@/main.js';
import depart from '../assets/depart2.mpeg';
import arrival from '../assets/arrival2.mpeg';
import DefaultModal from "@/components/modals/defaultModal.vue";
import TaskOptionModal from "@/components/modals/task/taskOptionModal.vue";
import DefaultHeader from '@/components/header/defaultHeader.vue';

export default {
  name: 'Index',
  components: {
    TaskOptionModal,
    DefaultModal,
    MapComponent,
    DefaultHeader,
  },
  data() {
    return {
      showLocations: true,
      destination: null,
      showList: true,

      showMoving: false,
      showStop: false,
      showArrived: false,

      locations: [],
      jobs: [],
      jobId: null,
      workName: null,
      modalState: {
        message: null,
        action: null,
        cancelMessage: null,
      },
      showModal: false,
      progress: '',

      showTaskOptionModal: false,
      modalConfigSelected: null,
      modalConfigNumber: null,
      defaultNumber: null,
      workAllList: [],
      workId: null,
      work: null,
    };
  },
  created() {
    EventBus.$on('taskConfig', (configs) => {
      this.modalConfigSelected = configs.selected;
      this.modalConfigNumber = configs.inputNumber;
    });
    EventBus.$on('showTaskName', data => {
      this.workName = data;
    });
    EventBus.$on('robotStatus', this.updateRobotStatus);
    EventBus.$on('toggleList', this.toggleList);
    EventBus.$on('popLoadingEstimate', this.popLoadingEstimate);

    this.initialize();
  },
  beforeDestroy() {
    EventBus.$off('taskConfig');
    EventBus.$off('showTaskName');
    EventBus.$off('robotStatus');
    EventBus.$off('toggleList');
    EventBus.$off('popLoadingEstimate');

    EventBus.$off('all_stop/index');
    EventBus.$off('job:work_finish/index');
    EventBus.$off('job:update/index');
    EventBus.$off('job:goto_location_id/index');
  },
  methods: {
    initialize() {
      EventBus.$off('all_stop/index');
      EventBus.$off('job:work_finish/index');
      EventBus.$off('job:update/index');
      EventBus.$off('job:goto_location_id/index');

      EventBus.$on('all_stop/index', ()=> {
        if (!this.$store.state.isTaskStop) {
          this.$store.commit('stopTask');
        }
        if (this.showMoving) {
          this.showMoving = false;
          this.showStop = true;
          this.showArrived = false;
        }
      });

      EventBus.$on('job:work_finish/index', (data)=> {
        this.fetchJob(data).catch(err=> {throw Error(err)});
      });

      EventBus.$on('job:update/index', (data)=> {
        this.fetchJob(data).catch(err=> {throw Error(err)});
      });

      EventBus.$on('job:goto_location_id/index', (data)=> {
        const workId = data.split("/")[1];
        this.$axios.get(`/work/list/${workId}`)
          .then(res => {
            if (res.status === 200) {
              this.workName = res.data[0].work_name;
            }
          })
          .catch(err => {throw Error(err)});
      });

      return this.fetchData()
        .then(this.fetchJob(true))
        .then(this.fetchWorkAllList)
        .catch(err => {
          throw Error(err);
        });
    },
    fetchData() {
      return this.$axios.get('/location')
        .then(res => {
          if (res.status === 200) {
            this.locations = res.data;
          }
        });
    },
    updateRobotStatus() {
      if(this.showMoving){
        let arrivalAudio = new Audio(arrival);
        arrivalAudio.play();
      }
      this.showMoving = false;
      this.showStop = false;
      this.showArrived = true;
    },
    toggleList(){
      this.showList= !this.showList;
    },
    popLoadingEstimate(){
      this.modalState.message = '로봇의 위치를 조정중입니다. 잠시만 기다려 주세요.';
      this.modalState.action = 'no-action';
      this.modalState.cancelMessage = null;
      this.showModal = true;
      setTimeout(()=>{
        this.closeModal();
      }, 2000);
    },
    clickTaskPauseBtn() {
      this.$store.state.isTaskStop ? this.$store.commit('reStartTask') : this.$store.commit('stopTask');

      if (this.$store.state.isTaskStop) {
        return this.fetchPause();
      } else {
        this.fetchResume();
      }
    },
    fetchPause() {
      return this.$axios.get('/job/pause')
        .catch(err => {
          throw Error(err);
        });
    },
    fetchResume() {
      return this.$axios.get('/job/resume')
        .catch(err => {
          throw Error(err);
        });
    },
    cancelTask() {
      this.modalState.message = '진행중인 태스크를 취소하시겠습니까?';
      this.modalState.action = '태스크 취소';
      this.modalState.cancelMessage = '아니오';
      this.showModal = true;
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.modalState.cancelMessage = null;
      this.showModal = false;
    },
    doAction() {
      if (this.modalState.action === '태스크 취소') {
        this.showModal = false;
        const id = this.jobId;
        this.$store.commit('reStartTask');

        return this.$axios.delete(`/job/${id}`)
          .then(()=> this.fetchJob(true))
          .then(()=> {
            EventBus.$emit('clearTaskInfo');
          })
          .catch(err => {
            throw Error(err);
          });
      }
      this.closeModal();
    },
    fetchStop() {
      return this.$axios.put('/job/stop').catch(err => {
        throw Error(err);
      });
    },
    fetchJob(voidAudio) {
      return this.$axios.get('/job')
        .then(res => {
          if (res.status === 200) {
            this.jobs = res.data;
            if (this.jobs.length === 0) {
              if(this.jobId&& !voidAudio){
                this.jobId = null;
                let arrivalAudio = new Audio(arrival);
                arrivalAudio.play();
              }
              this.$store.commit('reStartTask');
            } else {
              if(!voidAudio){
                let departAudio = new Audio(depart);
                departAudio.play();
              }
              this.jobId = this.jobs[0].job_id;
              this.progress = `(${this.jobs[0].job_done_count}/${this.jobs[0].job_repeat})`
            }
            EventBus.$emit('socketEmit', 'job:goto_location_id');
          }
        });
    },
    moveToLocation(id, destination) {
      this.showController = false;
      return this.$axios.put(`/nav/to/${id}`)
        .then(res => {
          if (res.status === 200) {
            let departAudio = new Audio(depart);
            departAudio.play();

            this.destination = destination;
            this.showLocations = false;
            this.showMoving = true;
            this.showStop = false;
            this.showArrived = false;
          }
        })
        .catch(err => {
          this.$toast('태스크 수행 중 명령을 할 수 없습니다', {
            position: 'top-center'
          });
          throw Error(err);
        });
    },
    stopMoving() {
      return this.$axios.put('/nav/cancel')
        .then(res => {
          if (res.status === 200) {
            this.showMoving = false;
            this.showStop = true;
            this.showArrived = false;
          }
        })
        .catch(err => {
          throw Error(err);
        });
    },
    cancelMoving() {
      this.showLocations = true;
      this.showMoving = false;
      this.showStop = false;
      this.showArrived = false;
      this.destination = null;
      this.$axios.put('/nav/reset');
    },
    keepMoving() {
      return this.$axios.put('/nav/resume')
        .then(res => {
          if (res.status === 200) {
            this.showMoving = true;
            this.showStop = false;
            this.showArrived = false;
          }
        })
        .catch(err => {
          throw Error(err);
        });
    },
    closeOptionModal() {
      this.showTaskOptionModal = false;
      this.modalConfigSelected = null;
      this.modalConfigNumber = null;
    },
    addOnceWorkToJob(index) {
      this.work = this.workAllList[index];
      return this.$axios.post('/job', {
        map_id: this.work.map_id,
        work_id: this.work.work_id,
        job_state: "STBY",
        job_repeat: parseInt(1),
        job_start_count: 0,
        job_done_count: 0,
        job_fail_count: 0,
        job_order: 5
      }).then(() => {
        EventBus.$emit('socketEmit', 'changeJob');
      }).then(()=>this.fetchJob(this.jobId!== null))
      .catch(err => {
        throw Error(err);
      });
    },
    addWorkToJob() {
      if (Boolean(this.modalConfigSelected) && Boolean(this.modalConfigNumber)) {
        const selected = this.modalConfigSelected;
        const number = this.modalConfigNumber;

        if (number > 0) {
          this.closeOptionModal();

          localStorage.setItem(`job_${this.work.map_id}_${this.work.work_id}`, number);

          return this.$axios.post('/job', {
            map_id: this.work.map_id,
            work_id: this.work.work_id,
            job_state: "STBY",
            job_repeat: parseInt(number),
            job_start_count: 0,
            job_done_count: 0,
            job_fail_count: 0,
            job_order: selected
          })
            .then(() => {
              EventBus.$emit('socketEmit', 'changeJob');
            })
            .then(this.fetchJob(this.jobId!== null))
            .catch(err => {
              throw Error(err);
            });
        } else {
          this.$toast('0보다 큰 수를 입력해 주세요', {
            position: 'top-center'
          });
        }

      } else {
        this.$toast('우선순위와 횟수를 모두 입력해 주세요', {
          position: 'top-center'
        });
      }
    },
    showAddJobModal(index) {
      this.work = this.workAllList[index];
      this.defaultNumber = localStorage.getItem(`job_${this.work.map_id}_${this.work.work_id}`);
      this.showTaskOptionModal = true;
    },
    fetchWorkAllList() {
      return this.$axios.get('/work/allList')
        .then(res => {
          if (res.status === 200) {
            this.workAllList = res.data;
          }
        });
    },
  }
}
</script>

<style scoped>

.title-task {
  color: #ffffff;
  font-size: 50px;
}

.empty {
  color: #ffffff;
  font-size: 20px;
  text-align: center !important;
}

.task {
  border-radius: 15px !important;
  vertical-align: middle;
  font-size: 50px;
  color: #7c7c7c !important;
}

.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
}

.background > .left-right-container {
  height: 95vh;
}

.background > .left-right-container > .right-area > .btn-area {
  height: 85vh;
  overflow: auto;
}

.background > .left-right-container > .left-area {
  padding: 20px;
}

.btn-list {
  list-style-type: none;
  padding-left: 0;
}

.btn {
  width: 100%;
  height: 60px;
  text-align: center;
}

.btn > .v-btn {
  width: 100%;
  height: 100% !important;
  background-color: #00BFB4 !important;
  font-size: 40px !important;
  color: #ffffff;
  border-radius: 10px;
  font-family: sans-serif;
  font-weight: bold;
  /* padding: 25px; */
}

.btn-state {
  width: 50%;
  height: 70px !important;
  font-size: 30px !important;
  border-radius: 10px;
}

.background > .left-right-container > .right-area > .move-view {
  height: 80vh;
}

.background > .left-right-container > .right-area > .move-view .move-title {
  font-size: 5vw;
  color: #00BFB4;
}

.background > .left-right-container > .right-area > .move-view .move-state {
  text-align: center;
  font-size: 4vw;
  color: #ffffff;
}

.task > .row > .col {
  padding: 0 !important;
}


@media (max-width: 1700px) {
  .header img {
    width: 40px;
    height: 40px;
  }

  .span-back {
    font-size: 40px !important;
  }

  .span-task-add {
    font-size: 40px !important;
  }

  .task {
    height: 50px;
    font-size: 30px;
  }

  .task > .row > .col {
    padding: 0 !important;
  }

  .btn-task {
    height: 40px !important;
    font-size: 30px !important;
  }

}

@media (max-width: 1024px) {

  .btn {
    width: 100%;
    height: 30px;
    font-size: 20px !important;
    border-radius: 10px;
  }

  .btn > .col {
    padding: 12px;
  }

  .btn-state {
    width: 80%;
    height: 50px !important;
    font-size: 20px !important;
    border-radius: 10px;
  }

  .btn > .v-btn {
    width: 100%;
    height: 100% !important;
    background-color: #00BFB4 !important;
    font-size: 16px !important;
    color: #ffffff;
    border-radius: 10px;
    font-family: sans-serif;
    font-weight: bold;
    /* padding: 25px; */
  }

  .title-task {
    font-size: 16px;
  }

  .task {
    height: 35px;
    font-size: 19px;
  }

  .btn-task {
    height: 25px !important;
    font-size: 12px !important;
  }

  .empty {
    color: #ffffff;
    font-size: 12px;
    text-align: center !important;
  }
}

</style>
